<template>
      <div class="bg d-flex row justify-content-center align-items-center">
        <div class="">
          <h1 class="title title-1"><strong>¿Cómo funcionan los préstamos de Ibancar?</strong></h1>
        </div>
      </div>

      <div class="my-5 d-flex align-items-center justify-content-center container">
        <div class="video-container">
          <iframe
                  src="https://www.youtube.com/embed/zhRlf8eMLes?si=k2IMqHxz4RZBffpY"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen>
          </iframe>
        </div>
      </div>
        <div class="container-fluid pt-3">
            <div class="row justify-content-center pb-3">
                <div class="col-xs-12 col-sm-12 col-md-11 col-lg-12 col-xl-9 col-xxl-9 pb-3">
                        <div id="rapido" class="card">
                            <div class="card-body">
                                <div class="row d-flex align-items-center">
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-7 col-xl-8 col-xxl-8 p-3">
                                        <h2 class="h4-orange pb-2"><strong>El préstamo que necesitas de forma rápida y sin moverte de casa.</strong></h2>
                                        <p class="card-text pb-2">
                                            Más económico e igual de rápido que un minicrédito.
                                        </p>
                                        <p class="card-text pb-2">
                                            Ibancar ha desarrollado la mejor solución para que puedas obtener un crédito y continúes conduciendo tu coche.
                                        </p>
                                        <p class="card-text pb-2">
                                            Podemos ayudarte consiguiendo <strong>un préstamo en menos de 24 horas</strong> usando tu coche como garantía, pero no te preocupes, 
                                            <strong>no cambiamos la titularidad, sigues usándolo y no hay que pagar</strong> renting como en la mayoría de la competencia.
                                        </p>
                                        <p class="card-text pb-2">
                                            Lo único que no puedes hacer es vender tu coche durante el préstamo, por lo demás funcionamos como cualquier empresa de préstamos sin aval.
                                        </p>
                                    </div>
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-4 col-xxl-4 p-3 text-center">
                                        <img width="294" height="294" class="img-fluid p-2" src="@/assets/img/reloj.svg" alt="ibancar">
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-11 col-lg-12 col-xl-9 col-xxl-9 pb-3 pt-4">
                        <div id="asnef" class="card">
                            <div class="card-body">
                                <div class="row d-flex align-items-center justify-content-around">
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-7 col-xl-6 col-xxl-8 text-center">
                                        <img width="597" height="195" class="img-fluid p-2" src="@/assets/img/asnef_rai.svg" alt="Logo de Asnef y RAI">
                                    </div>
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xxl-4 ">
                                        <h2 class="h4-orange pb-2"><strong>Si además, por cualquier motivo estás en ASNEF o RAI, también te podemos ayudar.</strong></h2>
                                        <p class="card-text">
                                            A continuación te mostramos nuestras ventajas, pero sobre todo y más importante, es que estamos a  tu disposición y puedes contactar con nosotros para que te contemos todos los detalles.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </div>
        <div id="ventajas" class="container-fluid pt-3">
            <div class="row justify-content-center pb-4">
                <button type="button" @click="goTo('/contacto',null)" class="btn-orange">Contacta con nosotros</button>
            </div>
        </div>
          <div class="container-fluid pt-3">
            <div class="row justify-content-center pb-3">
                <div class="col-xs-12 col-sm-12 col-md-11 col-lg-11 col-xl-9 col-xxl-9">
                    <div  class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6 p-3">
                                        <h2 id="ventajasIbancar" class="h4-orange"><strong>Las ventajas de Ibancar</strong></h2>
                                        <p class="card-text">
                                            <ul class="lista-ventajas">
                                                <li>
                                                <span class="green-text pr-2">✓</span>   No cambiamos la titularidad de tu coche
                                                </li>
                                                <li>
                                                    <span class="green-text pr-2">✓</span>   No existe alquiler ni retirada del vehículo
                                                </li>
                                                <li>
                                                    <span class="green-text pr-2">✓</span>  Tu dinero en 24 horas
                                                </li>
                                                <li>
                                                    <span class="green-text pr-2">✓</span>   Proceso 100% online
                                                </li>
                                                <li>
                                                    <span class="green-text pr-2">✓</span>   Amortiza tu préstamo desde la primera cuota
                                                </li>
                                                <li>
                                                    <span class="green-text pr-2">✓</span>   Pack de servicios incluído
                                                </li>
                                                <li>
                                                    <span class="green-text pr-2">✓</span>   Sin entrega de copias de llaves ni documentación original
                                                </li>
                                            </ul>
                                        </p>
                                    </div>
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6 text-center p-3">
                                        <img width="600" height="329" class="img-fluid p-2" src="@/assets/img/screen_3.webp" alt="Pareja conduciendo un coche">
                                    </div>
                                </div>
                            </div>
                        </div>
                            <div id="documentacion" class="p-2">
                                <router-link to="/credito-coche/#packServicios"><p><small>Ver servicios incluidos</small></p></router-link>
                            </div>
                    </div>
                </div>
            </div>
            <div  class="container-fluid pt-3">
                <div class="d-flex flex-row justify-content-center">
                    <div class="col-xl-9 xol-xxl-9">
                        <h2 id="documentacion" class="h4-orange pb-3">
                            <strong>¿Qué documentación necesitas?</strong>
                        </h2>
                    </div>
                </div>
            <div class="row d-flex justify-content-center pb-3">
                <div class="col-xs-12 col-sm-12 col-md-11 col-lg-11 col-xl-4 col-xxl-4 m-3 ">
                        <div id="personal" class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 padding">
                                        <div class="row justify-content-between align-items-center">
                                            <h4 class="h4-title">Documentación Personal</h4>
                                            <img width="61" height="70" class="img-fluid" src="@/assets/img/doc-personal.svg" alt="Ibancar">
                                        </div>
                                        <div class="row">
                                            <p class="card-text">
                                                <ul class="lista-documentacion">
                                                    <li>
                                                        <a href="#" @click="openModal('modal_dni', 'Parte delantera y trasera de documento de identidad. Sin sombras ni reflejos del flash, sin cortar esquinas y con un fondo blanco ', ['dni1.webp','dni2.webp'])"  data-toggle="modal" :data-target="'#'+nombre_modal">DNI ambas caras en fondo blanco</a>
                                                    </li>
                                                    <li>
                                                        <a href="" @click="openModal('modal_justificante_ingresos', 'Nómina del último mes', ['nomina.webp'])"  data-toggle="modal" :data-target="'#'+nombre_modal">Última nómina, pensión o autónomo</a>
                                                    </li>
                                                    <li>
                                                        <a href="" @click="openModal('imagen_selfie', ' Foto Selfie con DNI ', ['imagen_selfie.svg'])"  data-toggle="modal" :data-target="'#'+nombre_modal"> Foto Selfie con DNI </a>
                                                    </li>
                                                   
                                                </ul>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-11 col-lg-11 col-xl-4 col-xxl-4 m-3">
                        <div id="vehiculo" class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 padding">
                                        <div class="row justify-content-between align-items-center">
                                            <h4 class="h4-title">Documentación del vehículo</h4>
                                            <img width="49" height="62" class="img-fluid" src="@/assets/img/doc-vehiculo.svg" alt="ibancar">
                                        </div>
                                        <div class="row">
                                            <p class="card-text">
                                                <ul class="lista-documentacion">
                                                    <li>
                                                        <a href="#" @click="openModal('permiso_circulacion', 'Permiso de circulación y ficha técnica', ['circulacion.webp', 'fichatecnica.webp'])"  data-toggle="modal" :data-target="'#'+nombre_modal">Permiso de circulación y ficha técnica</a>
                                                    </li>
                                                    <li>
                                                        <a href="#" @click="openModal('cuentakilometros', '1 Foto del cuenta kilómetros del coche con DNI ', ['cuentakms.webp'])"  data-toggle="modal" :data-target="'#'+nombre_modal">1 Foto del cuentakilómetros con DNI</a>
                                                    </li>
                                                    <li>
                                                        <a href="#" @click="openModal('fotos', 'Fotos del coche (4 fotos desde los cuatro lados del coche)', ['4fotos.webp'])"  data-toggle="modal" :data-target="'#'+nombre_modal">4 fotos del coche</a><a href="#" @click="openModal('video', 'Video del coche (1 video alrededor del coche)', ['video.webp'])"  data-toggle="modal" :data-target="'#'+nombre_modal"> / 1 Video del coche</a>
                                                    </li>
                                                </ul>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="p-2 row justify-content-end">
                            <router-link to="/como-funciona/escaner"><p><small>¿Cómo escanear con el móvil?</small></p></router-link>
                        </div>
                    </div>
                </div>
            <div class="container-fluid pt-2">
                <div class="row justify-content-center pb-5">
                    <button type="button" class="btn-orange" @click="goTo('/','')">Solicitar préstamo</button>
                </div>
            </div>
        </div> 
        <div class="my-5 d-flex align-items-center justify-content-center container">
            <div class="video-container">
              <iframe
                      src="https://www.youtube.com/embed/yfPVs9u9y3U?si=Jv7yBfLq6UZcWK2S"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerpolicy="strict-origin-when-cross-origin"
                      allowfullscreen>
              </iframe>
            </div>
          </div>
            <Slogan />
        <div>
            <ModalGenerica :modalName="nombre_modal" :title="title" :img="img_url" />
        </div>
</template>

<script>
import ModalGenerica from '@/components/ComoFunciona/ModalGenerica.vue';
import Slogan from '@/components/Slogan.vue';

export default {
  name: "ComoFuncionaS1",
  components:{
      ModalGenerica,
      Slogan
  },
  data() {
      return {
          nombre_modal: '',
          title: '',
          img_url: null
      }
  },
  methods: {
      goTo(route,hash) {
          this.$router.push({path: route, hash: hash});
      },
      openModal(nombre, title, img_url) {
          this.nombre_modal = nombre;
          this.title = title;
          this.img_url = img_url;
      }
  }
};
</script>

<style scoped lang="scss">

.title {
  width: 100%;
  font-family: 'Montserrat';
  font-display: swap;
  font-style: normal;
  line-height: auto;
  text-align: center;
  color: #FFFFFF;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.h4-orange {
    color: #FFA500;
    font-weight: 550;
    font-size: 1.5rem;
}
.card {
    line-height: 1.8rem;
}
.lista-ventajas {
    list-style: none;
    .green-text {
        font-weight: bold;
        line-height: 5.5vh;
        color: #4AC068;
    }   
}
.lista-documentacion {
    line-height: 5.5vh;
    li a {
        text-decoration: none;
    }
}

  // Resposive para pantallas a partir de tamaño medio a pequeño

@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

@include media-breakpoint-between(xs, sm) {
    .bg {
        margin-top: 67px;
    }
    .title-1{
        font-size: 1.3rem;
        line-height: 40px;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
    .title-h2 {
        font-size: 18px;
        line-height: 33px;
    }
    .h4-title {
        font-size: 0.85rem;
        text-decoration: underline;
        font-weight: 600;
    }
    .h4-orange {
        font-size: 18px;
    }
    .padding {
        padding: 0;
    }
    .lista-ventajas {
        padding: 0;
    }
    .bg {
        padding: 1rem;
        margin-top: 61px
    }
    #personal {
        .img-fluid {
            width: 15%;
        }
    }
    #vehiculo {
        .img-fluid {
            width: 15%;
        }
    }


}

@include media-breakpoint-between(md, lg) {
    .bg {
        margin-top: 91px;
    }
    .title-1{
        font-size: 1.5rem;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
    .title-h2 {
        font-size: 1.25rem;
        line-height: 55px;
    }
    .card {
        background-color: #fbfbfb;
        height: auto;
        p{
            line-height: auto;
            font-size: 18px;
        }
    }
    .h4-title {
        font-size: 0.95rem;
        text-decoration: underline;
        font-weight: 600;
    }
    .img-fluid {
        width: 80%;
    }
    .padding {
        padding: 0 25px 0 25px;
    }
    .h4-orange {
        font-size: 0.95rem;
        text-align: left;
    }
    ul{
        li{
            font-size: 18px;
        }
    }
    .lista-ventajas {
        li {
            padding: 0;
            text-align: left;
        }
    }
    #personal {
        .img-fluid {
            width: 10%;
        }
    }
    #vehiculo {
        .img-fluid {
            width: 10%;
        }
    }
    #documentacion {
        padding-left: 50px;
    }
}

@include media-breakpoint-between(xl, xxl) {
    .bg {
        margin-top: 91px;
    }
    .title-1{
    font-size: 1.75rem;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
    .title-h2 {
        font-size: 1.75rem;
        line-height: 50px;
    }
    .card {
    background-color: #fbfbfb;
    height: auto;
    p {
        line-height: auto;
        font-size: 18px;
    }
    .h4-orange {
        text-align: left;
    }
    #ventajasIbancar {
        padding-left: 50px;
    }
    .padding {
        padding: 1.1.5rem;
    }
    }
    #personal {
        height: auto;
        .h4-title {
            font-size: 1.25rem;
        }
    }
    #vehiculo {
        height: auto;
        .h4-title {
            font-size: 1.25rem;
        }
    }

}

</style>
